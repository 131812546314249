import '../item.scss';
import './styles.scss';

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import useHover from '@assets/scripts/hooks/useHover';

const ItemLogo = ({
	modifiers,
	className,
	image,
	link,
	imgStyle = {},
	...otherProps
}) => {
	const baseClass = 'item-logo';
	const defaultClass = 'item';

	const rootClass = cx(baseClass, className, defaultClass, {
		[`${baseClass}--${modifiers}`]: modifiers,
	});

	// interaction
	const [hoverOn, hoverOff] = useHover();

	return (
		<div className={rootClass} data-item {...otherProps}>
			{link ? (
				<a
					href={link}
					target="_blank"
					rel="noopener noreferrer"
					data-target
					onMouseEnter={() => hoverOn()}
					onMouseLeave={() => hoverOff()}
				>
					<img
						src={image.src}
						alt={image.alt || ''}
						loading="lazy"
						style={imgStyle}
					/>
				</a>
			) : (
				<img
					src={image.src}
					alt={image.alt || ''}
					loading="lazy"
					style={imgStyle}
				/>
			)}
		</div>
	);
};

ItemLogo.propTypes = {
	modifiers: PropTypes.string,
	className: PropTypes.string,
	image: PropTypes.object.isRequired,
	link: PropTypes.string,
	imgStyle: PropTypes.object,
};

export default ItemLogo;
