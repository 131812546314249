import '@assets/scss/pages/project.scss';

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

// components
import Layout from '@src/layouts';
import Video from '@components/atoms/Video';
import Blockquote from '@components/molecules/Blockquote';
import Column from '@components/organisms/Column';
import Gallery from '@components/organisms/Gallery';
import HeroProject from '@components/molecules/heros/HeroProject';
import Image from '@components/atoms/Image';
import ListText from '@components/organisms/ListText';
import ItemLogo from '@components/molecules/items/ItemLogo/index';
import nextLogo from '@assets/images/projects/eca/nextjs.svg';

// --------------------------------
// #region data
// --------------------------------

const language = 'fr';

const pageClass = 'page-project';

const accent_color = '#DA1B28';
const footerRelatedLinks = [
	{
		title: 'Freeride World Tour',
		url: '/fr/projets/fwt-freeride-world-tour/',
	},
];

// --------------------------------
// #endregion
// --------------------------------

const ProjectEcaPage = (props) => {
	const data = useStaticQuery(
		graphql`
			query {
				heroImage: file(
					relativePath: { eq: "projects/eca/SUPT_ECA_001.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				heroImageMobile: file(
					relativePath: { eq: "projects/eca/SUPT_ECA_001-mobile.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1000, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_1: file(
					relativePath: { eq: "projects/eca/SUPT_ECA_003.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_2: file(
					relativePath: { eq: "projects/eca/SUPT_ECA_004.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_3: file(
					relativePath: { eq: "projects/eca/SUPT_ECA_005.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_4: file(
					relativePath: { eq: "projects/eca/SUPT_ECA_006.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_5: file(
					relativePath: { eq: "projects/eca/SUPT_ECA_009.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_6: file(
					relativePath: { eq: "projects/eca/SUPT_ECA_010.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_7: file(
					relativePath: { eq: "projects/eca/SUPT_ECA_011.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_8: file(
					relativePath: { eq: "projects/eca/SUPT_ECA_012.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	);

	return (
		<Layout
			language={language}
			pageClass={pageClass}
			footerRelatedLinks={footerRelatedLinks}
			accentColor={accent_color}
			location={props.location}
			translations={[
				{
					uri: '/en/projects/eca-vaud/',
					lang: 'en',
				},
			]}
			title="ECA Vaud - Par Superhuit"
		>
			<HeroProject
				breadcrumb={{
					title: 'Projets',
					link: '/fr/projets/',
				}}
				title="ECA Vaud"
				image={{
					...data.heroImage.childImageSharp,
				}}
				imageMobile={{
					...data.heroImageMobile.childImageSharp,
				}}
			/>
			<section className="grid grid-content" aria-label="Projet">
				<div className="project-intro" data-animation-page>
					<div className="project-summary text-content">
						<p className="p-medium">
							Simplifier et unifier une architecture complexe.
							Proposer une nouvelle signature digitale.
						</p>

						<ListText
							className="element-spacing"
							title="Nos services"
							list={[
								'Workshops Design Thinking',
								'Conception UX',
								'Design UI',
								'Développement web headless',
								'Accompagnement contenu',
							]}
						/>
					</div>
					<div className="project-description text-content">
						<h3>Le contexte</h3>
						<p>
							L’ECA était en pleine transformation dans son
							organisation interne et digitale avec, pour point
							culminant, le déménagement dans un nouveau bâtiment
							emblématique et le regroupement des équipes de
							verticales métiers différents. Dans ce contexte, une
							refonte de son site s’imposait, afin d’y faire
							refléter fidèlement l’ensemble de ces
							transformations.
						</p>

						<h3>L'enjeu</h3>
						<p>
							L’un des défis majeurs résidait dans la refonte de
							l’architecture de l’information du site qui comprend
							plus de 1500 pages en faveur d’audiences aux besoins
							très différents, cela en réussissant à aligner les
							trois verticales métiers et les six équipes
							transverses.
						</p>

						<h3>Notre réponse</h3>
						<p>
							Création d’un processus de Design Thinking sur
							mesure permettant de synthétiser les besoins, les
							aspirations et les enjeux du site avec les réalités
							des utilisateurs, agrémentée d’un nouveau socle
							technologique ayant pour objectif de poser une
							fondation solide pour l’avenir web de l’ECA,
							s’appuyant sur notre technologie Wordpress headless
							à la fois performante et sécurisée.
						</p>
					</div>
				</div>
				<Column modifiers="right">
					<div className="column__text column__sticky text-content">
						<h3>Workshops stratégiques, conception UX</h3>
						<p>
							La méthode de Design Thinking a été mise en œuvre
							par une série de 14 ateliers participatifs
							impliquant plus de 20 collaboratrice.eur.s de l’ECA.
							Cette démarche a été soutenue par une analyse à la
							fois quantitative et qualitative, fournissant un
							cadre rigoureux pour confirmer ou infirmer les
							hypothèses initiales.
						</p>
						<p>
							Ces données ont permis de définir l’architecture de
							l’information et d’élaborer les wireframes. Ces
							derniers ont été soumis à des tests utilisateurs,
							assurant ainsi la pertinence et l’efficacité des
							parcours.
						</p>
					</div>
					<div
						className="column__media text-content"
						style={{ gridRow: 1 }}
					>
						<Video
							src="aa33e6f0d846498a1db9ddaf832ffa94"
							alt=""
							className="video__middle"
						/>
					</div>
				</Column>

				<Image
					className="col-full"
					{...data.image_1.childImageSharp}
					alt="Site web ECA"
				/>

				<Image
					className="col-full grid-spacing"
					{...data.image_2.childImageSharp}
					alt="Pages du site web ECA"
				/>

				<div className="box text-content">
					<h3>
						Une identité digitale épurée mettant l’emphase sur
						l’humain
					</h3>
					<div className="list-two-columns grid-spacing">
						<div className="text-content">
							<p>
								Une évolution de l’identité visuelle La nouvelle
								signature digitale fait évoluer la marque ECA
								dans une nouvelle ère en contribuant activement
								à remodeler la perception de l’entreprise auprès
								de ses différentes audiences. Cette nouvelle
								identité digitale épurée et lumineuse par la
								proéminence du blanc symbolise la transparence
								et la confiance. La grille structurée veut
								volontairement appuyer un émotionnel sécurisant
								; elle utilise aussi des zones de respiration
								permettant d’augmenter l’impression d’espace.
							</p>
						</div>
						<div className="text-content">
							<p>
								Une imagerie qui exprime la proximité L’imagerie
								est intentionnellement conçue pour être
								chaleureuse et profondément humaine, dépeignant
								des interactions dans des ambiances
								quotidiennes. L’imagerie raconte une entreprise
								proche qui s’intègre dans votre réalité.
							</p>
						</div>
					</div>
				</div>

				<Video
					className="col-full"
					src="38f51636f470b9223b38a57e7eb5f7a9"
				/>

				<Gallery className="col-full grid-spacing" modifiers="gutter">
					<Image {...data.image_3.childImageSharp} alt="Assurances" />
					<Image
						{...data.image_4.childImageSharp}
						alt="Site internet ECA"
					/>
				</Gallery>

				<Blockquote
					modifiers="big"
					uptitle="Témoignage client"
					quote="Une très belle rencontre avec des gens passionnés et véritablement experts qui a permis d’avancer sereinement tout au long de ce projet transverse impliquant de nombreux acteurs."
					more={
						<>
							<span className="text-content">
								Les motifs de satisfaction à l’issue du projet
								sont multiples. Nous avons particulièrement
								apprécié l’expertise et les nombreuses
								compétences attribuées au projet. La créativité,
								le sens du travail bien exécuté, la méthodologie
								design thinking et de projet rigoureuse et
								pragmatique proposée ont fortement contribué à
								atteindre les objectifs fixés.
							</span>
							<span className="text-content">
								La collaboration et la confiance réciproque
								entre superhuit et nos équipes ont permis
								d’avancer sereinement et sainement tout au long
								de ce projet transverse impliquant de nombreux
								acteurs. Cela a débouché sur une très belle
								rencontre avec des gens passionnés et
								véritablement experts dans leur domaine.
							</span>
						</>
					}
					cite="Rafael Stuker – responsable communication ECA"
				/>

				<Gallery className="col-full" modifiers="gutter">
					<Image
						{...data.image_5.childImageSharp}
						alt="Site internet ECA sur tablette"
					/>
					<Image
						{...data.image_6.childImageSharp}
						alt="Site internet ECA"
					/>
				</Gallery>

				<Image
					className="col-full grid-spacing"
					{...data.image_7.childImageSharp}
					alt="Site internet ECA mobiles"
				/>

				<div className="box text-content">
					<h3>Solution headless wordpress</h3>
					<div className="list-two-columns grid-spacing">
						<div className="text-content">
							<p>
								Le site s’appuie sur notre technologie headless
								fondée sur NextJS/React qui utilise Wordpress
								comme CMS.
							</p>
							<p>
								L’objectif est celui d’offrir les meilleures
								conditions pour répondre aux enjeux SEO,
								sécuritaires, de performance et d’évolutivité.
							</p>
						</div>
						<ItemLogo
							image={{
								src: nextLogo,
								alt: 'Logo NextJS',
							}}
							className="element-center element-spacing"
							imgStyle={{ width: '228px', maxWidth: 'unset' }}
						/>
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default ProjectEcaPage;
